import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(() => {
    const auth = useAuthStore()

    // @ts-expect-error
    if (auth.authenticated && auth.user && !auth.user?.creches?.length) {
        return navigateTo('/auth/logout')
    }
})
